import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

    <Layout>
        <section className="section-home">

            <div id="root">
                <div className="ae af">


                    <main id="main">
                        <div
                            className="wcb3-billboard-cta b5 b6 b7 b8 b9 ba bb ae bx be jg bg bh bi bj c0 bl ji bn c2 bp jk br">
                            <div className="bj bs bt">
                                <h1>Vehicle Choices</h1>
                                <h2>Choose the Best Vehicle for Airport & City Transfers, Special Events, or Group
                                    Charters</h2><br/>

                                <p>You need to get to or from any airport, special event or have a small or large group
                                    that needs to be moved in any city and you don’t know which vehicle to choose?
                                </p>
                                <p>No worries, our over 30 years of experience and over 17 years on the internet, we’re
                                    here to help you decide which service to use and best vehicle(s) suited for your
                                    travel needs. We’ll get you there safely, on-time, and in comfort, regardless if
                                    just a one-way transfer, round-trip, hourly charter, shuttle, or a sightseeing tour
                                    to Washington, DC.</p>
                                <p>Use our exclusive Quotebot to search for the vehicle you want with all of the
                                    amenities that will make your experience with us special and memorable. And at the
                                    lowest available rate!
                                </p>
                                <h4>Sedan - Mid-Size Basic / Luxury (2 large luggage)</h4><br/>
                                <p>These vehicles are your best choice for the lowest cost to get 1 or 2 passengers from
                                    any two points. You can choose a basic sedan or a BMW or Infinity luxury model for
                                    even more style. Use the Quotebot filter to find the make and model that appeals to
                                    you budget or comfort level.
                                </p>
                                <h4>Sedan - Full-Size / Large Basic / Luxury (2 - 4 large luggage)</h4><br/>
                                <p>These vehicles are best utilized for 3 – 4 passengers with luggage or if you desire
                                    more leg room or cabin space. You can also choose a larger luxury Mercedes or Lexus
                                    model to meet your preferences regardless a mid-size or large size sedan.</p>
                                <h4>Crossover and SUVs - Mid-size and Large (3 - 6 large luggage)
                                </h4><br/>
                                <p>Move up in size and style differences for more cabin space and trunk space. Choose
                                    from almost every make and model to ride the way you want and where you want. These
                                    vehicles can be used for shared rides.</p>
                                <h4>Armored / Bodyguard / Security - Sedan, SUV, Van, Mini-Bus (2 - 38 large
                                    luggage)</h4><br/>

                                <p>Armored SUVs is becoming mainstream. Do you have a need for maximum protection
                                    locally or in another country? We can discreetly meet your security needs where
                                    available. These vehicles can seat, 3, 4, 6, 14 and 38 passengers, depending on
                                    which city.</p>
                                <h4>Vans – Minivan / Sprinter / Passenger (5 - 13 large luggage)</h4>
                                <p>Choose among the various size van’s that allow you more seating capacity, cabin
                                    space, and luggage capacity. Sprinters and passenger vans can be booked with many
                                    amenities including limo type interiors or captain chairs, DVD, satellite/TV with a
                                    large LCD screen. Some are designed like mobile offices or lounges. From 10 – 15
                                    passengers depending on which van type. These vehicles can be used as shared rides.
                                </p>
                                <h4>Stretch Limo</h4>
                                <p>If you want to make a statement, book one of our long and flashy stretch limos!
                                    These well recognized vehicles has a long-standing image of partying in style! These
                                    traditional limos can be used for any special event including weddings and proms.
                                    New stretch model designs are being carried over to larger SUVs of all makes and
                                    models. These stretches offer massive cabin space for room and comfort, leather
                                    wraparound seating, laser and LED lighting, DVD / TVs, great stereo music, wet bars,
                                    and more. No problem to seat up to 24 or more special guests.
                                </p>
                                <h4>Mini-Bus</h4>
                                <p>
                                    These buses are best used for smaller groups traveling shorter distances. The
                                    perfect use for these buses are to and from board meetings, nights out, sporting
                                    events, concerts, bar crawls, and wine and budz tours, shuttling people between
                                    hotels, airports, fine restaurants, and weddings. Depending on the city, alcohol can
                                    be brought aboard. These vehicles have a seating capacity form 20 to 29 passengers.
                                </p>
                                <h4>Limo Bus / Party Bus (not recommended for airport or seaport)</h4>
                                <p>These versatile buses are mini-buses in size and look more like the interior of a
                                    limousine with leather wraparound seating, colorful LED and laser lighting, DVD and
                                    wide screen TVs, fabulous stereo sounds, some with a dance pole, wet bar with a
                                    server (chaperone / security guard for safety in certain cities). These vehicles are
                                    perfect for weddings, pub and budz crawls, wine tours, group nights out from the
                                    hotel to dinner and more. These vehicles will seat 10 – 50 passengers.</p>
                                <h4>Trolley</h4>
                                <p>These vehicles are designed with nostalgia in mind to bring back a simpler time. Most
                                    have wooden benches with front view seating, with open or closed cabin window
                                    features. These vintage looking vehicles are perfect for weddings, short tours and
                                    nights on the town. Alcohol is allowed depending on the city. These vehicles will
                                    seat 20 – 58 passengers.
                                </p>
                                <h4>Motor Coach</h4>
                                <p>The motor coach is also referred to as a tour bus, charter bus, or motor coach. These
                                    vehicles are designed for long distance travel comfort, charter buses are typically
                                    used for larger group charters, and long or short distance shuttles to the airport
                                    and around town. These vehicles are equipped with many appealing amenities including
                                    DVD player, satellite TV or radio, Wi-Fi, lavatory, power outlets, PA system, and
                                    more. These vehicles can comfortably seat 47 to 57 passengers with plenty of room
                                    for luggage.
                                </p>
                                <h4>Executive Coach
                                </h4>
                                <p>These buses are used for an Office or Lounge on wheels. They are ideally used for a
                                    day or night move. These vehicles can seat 20 to 41 passengers.
                                </p>
                                <h4>Entertainer / Sleeper Coach</h4>
                                <p>Do want to tour like a rock star and in style? Then this is the super-bus for you!
                                    These vehicles are leased by musical groups & celebrities for extended touring,
                                    includes 9 or 12 small beds to accommodate sleeping. They are usually booked for 30
                                    or more days. Entertainer / Sleeper rates are itemized separately for the bus, the
                                    driver, and fuel costs when invoiced.
                                </p>
                                <h4>School Bus</h4>
                                <p>These orange colored buses are your best value for short trips and ideal to visiting
                                    the zoo, culture events, and shuttles to and from parking lots. These buses vary in
                                    capacity and can transport 25 passengers in micro-buses and 90 passengers in our
                                    larger buses</p>
                                <h4>Double Decker Bus
                                </h4>
                                <p>These buses are the rarities in the bus family and are mostly seen in large USA
                                    cities with either closed or open tops. Open tops are mostly used for city tours but
                                    more closed top double decker’s are being used for long distance travel between
                                    cities. The vehicles can seat 50 – 90 passengers.
                                </p>
                            </div>
                            <br/>
                        </div>
                    </main>
                </div>
            </div>
        </section>
    </Layout>
);

export default Page;
